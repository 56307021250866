import { useAuth } from "@Components/Auth/AuthContext";
import { CustomTextField } from "@Components/UI/StyledComponets";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunchOutlined";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControlLabel,
  Popper,
} from "@mui/material";
import { executePrompt } from "@Services.App/api";
import snackbarService from "@Services.App/snackbar";
import FilledTextField from "@UI/FilledTextField";
import MuiCheckbox from "@UI/MuiCheckbox";
import MuiSelect from "@UI/MuiSelect";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { readString } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  comparePrompts,
  getAIModels,
  getAllIterationData,
  getComparisonResult,
  getObjectives,
  getPromptDataAnalytics,
  getPromptDetail,
  savePrompt,
  updatePrompt,
} from "../../../../services/app/api";
import snackbar from "../../../../services/app/snackbar";
import {
  availableImageSizes,
  dalle3ImageSizes,
  emptyPrompt,
  imageVariations,
  llmModelsIcon,
} from "../../../../services/constants";
import {
  convertFileToBase64,
  formatOutputKeysToEdit,
  formatOutputKeysToSave,
  isValidURL,
  isValidXML,
} from "../../../../services/generalFunctions";

import MuiModal from "@UI/MuiModal";
import {
  addToEditPrompt,
  fetchAvailableCredits,
  fetchPrompts,
  handleChangEditPrompt,
  handleLoading,
  handleUpdatePromptId,
  removeFromEditPrompt,
  setAIModelsList,
  setModelsVersionsList,
  setObjectives,
} from "../../../../store/collectionsSlice";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";
import AppTabs from "../../../UI/AppTabs";
import {
  EditIcon,
  FileDraftIcon,
  FileTypeCircleIcon,
} from "../../../UI/IconPack";

import Textarea from "../../../UI/Textarea";
import TokenExpire from "../../common/TokenExpire";
import { formatOutputOfIteration } from "./CompareData";
import PromptCompare from "./PromptCompare";
import PromptDashboard from "./PromptDashboard";
import PromptData from "./PromptData";
import PromptOutput from "./PromptOutput";
import PromptReadOnly from "./PromptReadOnly";

const PromptEditor = () => {
  const templateRef = useRef(null);
  const newPromptsData = useSelector((state) => state.collectionsSlice);
  const { colors, mode } = useThemeContext();
  const {
    editPromptsList,
    modelsVersionsList,
    aiModelsList,
    allEnvironments,
    allPromptsData,
    all_objectives,
  } = newPromptsData;
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const promptList = promptData?.prompt_text?.prompt_data;
  const dispatch = useDispatch();
  const { activeTeam } = useAuth();
  const [selectedTab, setSelectedTab] = useState("Preferred Model");
  const [objectDetails, setObjectiveDetails] = useState("");
  const [openDiscardChanges, setOpenDiscardChanges] = useState(false);
  const params = useParams();
  const [errors, setErrors] = useState({
    llmModel: false,
    llmVersion: false,
    outputFormat: false,
    processType: false,
    name: false,
    prompt: false,
  });
  const [openDiscardPrompt, setOpenDiscardPrompt] = useState(false);
  const [discardPromptId, setDiscardPromptId] = useState(null);
  const [openTokenExpire, setOpenTokenExpire] = useState(false);
  const [emptyPromptData, setEmptyPromptData] = useState({
    name: "",
    prompt_text: {
      prompt_data: [
        {
          name: "action",
          action_val: "",
        },
      ],
    },
  });
  const [showAddPromptScreen, setShowAddPromptScreen] = useState(
    editPromptsList?.length === 0
  );
  const [showChangeModal, setShowChangeModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isPreviewMode, setIsPreviewMode] = useState(
    location.state?.previewMode
  );
  const [selectedModel, setSelectedModel] = useState(
    emptyPromptData?.llmModel || ""
  );
  const [selectedVersion, setSelectedVersion] = useState(
    emptyPromptData?.llmVersion || ""
  );

  const [fromVariable, setFromViable] = useState(location.state?.fromVariable);

  const examples = [
    {
      name: "Market Trends Analysis",
      llmModel: "open_ai",
      llmVersion: "gpt-4o",
      text: "Analyze the latest market trends in the technology sector and summarize key takeaways for investors.",
    },
    {
      name: "Customer Persona Generator",
      llmModel: "open_ai",
      llmVersion: "gpt-4o",
      text: "Generate a detailed customer persona for a SaaS product targeting mid-size enterprises.",
    },
    {
      name: "Risk Assessment Report",
      llmModel: "open_ai",
      llmVersion: "gpt-4o",
      text: "Draft a risk assessment report for implementing blockchain technology in supply chain operations.",
    },
    {
      name: "Leadership Strategy Plan",
      llmModel: "open_ai",
      llmVersion: "gpt-4o",
      text: "Create a leadership strategy for improving employee engagement and driving innovation in a corporate setting.",
    },
  ];

  const handleComparePrompt = async () => {
    let hasErrors = false;
    const allErrors = [];

    var iterration = parseInt(promptData?.iteration_cnt);
    if (iterration <= 0 || iterration > 30) {
      snackbarService.error({
        title: "The iteration count should be between 1 and 30.",
      });

      return;
    }
    promptData?.compare_data?.forEach((compare, index) => {
      if (!compare?.llmModel) {
        allErrors.push({
          title: `Invalid model`,
          description: `Select model for comparison column ${index + 1}`,
          type: "error",
        });
        hasErrors = true;
      }
      if (!compare?.model_version_id) {
        allErrors.push({
          title: `Invalid model version`,
          description: `Select model version for comparison column ${
            index + 1
          }`,
          type: "error",
        });
        hasErrors = true;
      }
    });

    if (hasErrors) {
      const data = {
        ...promptData,
        errors: allErrors,
      };
      snackbarService.error({
        title: allErrors[0].description,
      });
      dispatch(handleChangEditPrompt(data));
      return;
    }

    const data = {
      ...promptData,
      compareResponse: {},
      compareResultResponse: {},
      iterationResponse: [],
      comparisonProgress: true,
    };
    dispatch(handleChangEditPrompt(data));
    dispatch(handleLoading(true));

    const payload = {
      prompt_id: promptData?.id,
      data: {
        iteration_cnt: parseInt(promptData?.iteration_cnt || 1),
        compare_data: promptData?.compare_data.map((compareData) =>
          compareData?.context_id
            ? {
                model_version_id: compareData.model_version_id,
                temperature: compareData.temperature,
                max_tokens: compareData.max_tokens,
                variation_cnt: compareData.variation_cnt,
                context_id: compareData.context_id,
              }
            : {
                model_version_id: compareData.model_version_id,
                temperature: compareData.temperature,
                max_tokens: compareData.max_tokens,
                variation_cnt: compareData.variation_cnt,
              }
        ),
      },
    };

    try {
      const res = await comparePrompts(payload);
      const compareData = promptData.compare_data.map((compare) => ({
        ...compare,
        id: res.compare_data.find(
          (c) => c.model_version_id === compare.model_version_id
        )?.id,
      }));
      snackbarService.success({ title: `Result will generate soon.` });

      let totalApiCalledCount = 0;
      iterationInterval = setInterval(async () => {
        const allCompletePromises = compareData.map((compare) =>
          callIterations({
            model_compare_id: compare.id,
            comparison_id: res.id,
          })
        );
        totalApiCalledCount += 1;
        const allIterationResults = await Promise.all(allCompletePromises);
        const updatedData = {
          ...promptData,
          comparisonProgress: true,
          compareResponse: res,
          compare_data: compareData,
          compareResultResponse: {},
          iterationResponse: allIterationResults.map((iter) =>
            formatOutputOfIteration(iter)
          ),
        };
        dispatch(handleChangEditPrompt(updatedData));
        if (totalApiCalledCount >= 50) {
          iterationInterval && clearInterval(iterationInterval);
          const updatedData = {
            ...promptData,
            comparisonProgress: false,
            compareResultResponse: {},
            iterationResponse: [],
          };
          dispatch(handleChangEditPrompt(updatedData));
          snackbarService.error({
            title: `Failed to compare prompt please try again.`,
          });
          return;
        }
        if (
          allIterationResults.every((complete) => complete?.overall_complete)
        ) {
          const comparisonResult = await callComparisonResults({
            comparison_id: res.id,
          });
          clearInterval(iterationInterval);
          const updatedData = {
            ...promptData,
            comparisonProgress: false,
            compareResponse: res,
            compare_data: compareData,
            compareResultResponse: comparisonResult,
            iterationResponse: allIterationResults.map((iter) =>
              formatOutputOfIteration(iter)
            ),
          };
          dispatch(handleChangEditPrompt(updatedData));
          dispatch(fetchAvailableCredits({ team_id: activeTeam }));
        }
      }, 3500);
    } catch (error) {
      snackbarService.error({ title: `${error.error?.toString()}` });
      iterationInterval && clearInterval(iterationInterval);
      const updatedData = {
        ...promptData,
        comparisonProgress: false,
        compareResultResponse: {},
        iterationResponse: [],
      };
      dispatch(handleChangEditPrompt(updatedData));
    } finally {
      dispatch(handleLoading(false));
    }
  };

  const createPdg = () => {
    try {
      const doc = new jsPDF({
        orientation: "portrait",
        format: "letter",
        unit: "px",
        hotfixes: ["px_scaling"],
      });

      const html = templateRef.current;

      doc.html(html, {
        callback(doc) {
          doc.save("result");
        },
        margin: [40, 20, 40, 20],
      });
    } catch (error) {
      alert(error);
    }
  };

  const discardChangesHandler = async () => {
    if (validatePromptSelect()) return;

    try {
      dispatch(handleLoading(true));
      if (promptData?.id && !promptData.id.includes("newPromptId")) {
        const res = await getPromptDetail(promptData.id);
        if (!res || Object.keys(res).length === 0) {
          throw new Error("Prompt not found or invalid data");
        }
        let outputKeys = formatOutputKeysToEdit(res);
        let data = {
          ...promptData,
          ...res,
          output_keys: outputKeys,
        };

        dispatch(handleChangEditPrompt(data));
        snackbarService.success({
          title: `Changes discarded successfully`,
        });
      } else {
        snackbarService.warning({
          title: `Cannot discard changes for a new or invalid prompt`,
        });
      }
    } catch (error) {
      snackbarService.error({
        title: `Error discarding changes: ${error.message}`,
      });
    } finally {
      dispatch(handleLoading(false));
    }
  };
  useEffect(() => {
    if (promptData?.id?.includes("newPromptId")) {
      let data = {
        ...promptData,
        activeScreen: "Prompt Data",
        promptTabs: ["Prompt Data", "Response"],
        promptPanels: {
          "Prompt Data": <PromptData />,
          Response: (
            <PromptOutput
              outputFormatError={errors?.outputFormat}
              outputFormatOnFocus={() =>
                setErrors((ini) => ({ ...ini, outputFormat: false }))
              }
              isReadOnlyMode={false}
            />
          ),
        },
      };
      dispatch(handleChangEditPrompt(data));
    } else if (isPreviewMode) {
      let data = {
        ...promptData,
        activeScreen: "Prompt Data",
        promptTabs: ["Prompt Data", "Response"],
        promptPanels: {
          "Prompt Data": <PromptReadOnly />,
          Response: (
            <PromptOutput
              outputFormatError={errors?.outputFormat}
              outputFormatOnFocus={() =>
                setErrors((ini) => ({ ...ini, outputFormat: false }))
              }
              isReadOnlyMode={true}
            />
          ),
        },
      };
      dispatch(handleChangEditPrompt(data));
    } else {
      if (promptData?.promptTabs?.length != 2 || !fromVariable) {
        let data = {
          ...promptData,
          activeScreen: "Report Dashboard",
          promptTabs:
            promptData?.model_type !== "Image"
              ? ["Report Dashboard", "Prompt Data", "Compare", "Response"]
              : ["Report Dashboard", "Prompt Data", "Response"],
          promptPanels: {
            "Prompt Data": <PromptReadOnly />,
            "Report Dashboard": <PromptDashboard />,
            Response: (
              <PromptOutput
                outputFormatError={errors?.outputFormat}
                outputFormatOnFocus={() =>
                  setErrors((ini) => ({ ...ini, outputFormat: false }))
                }
                isReadOnlyMode={true}
              />
            ),
            Compare: <PromptCompare />,
          },
        };
        dispatch(handleChangEditPrompt(data));
      } else {
        let data = {
          ...promptData,
          activeScreen: "Prompt Data",
          promptTabs: ["Prompt Data", "Response"],
          promptPanels: {
            "Prompt Data": (
              <PromptData discardChangesHandler={discardChangesHandler} />
            ),
            Response: (
              <PromptOutput
                outputFormatError={errors?.outputFormat}
                outputFormatOnFocus={() =>
                  setErrors((ini) => ({ ...ini, outputFormat: false }))
                }
              />
            ),
          },
        };
        dispatch(handleChangEditPrompt(data));
      }
    }
    setShowAddPromptScreen(editPromptsList?.length === 0);
  }, [editPromptsList?.length]);

  const handleOpenEditMode = () => {
    let data = {
      ...promptData,
      activeScreen: "Prompt Data",
      promptTabs: ["Prompt Data", "Response"],
      promptPanels: {
        "Prompt Data": <PromptData />,
        Response: (
          <PromptOutput
            outputFormatError={errors?.outputFormat}
            outputFormatOnFocus={() =>
              setErrors((ini) => ({ ...ini, outputFormat: false }))
            }
          />
        ),
      },
    };
    dispatch(handleChangEditPrompt(data));
  };

  const handleAssetsChange = (v) => {
    console.log(v);
    var newSeq = JSON.parse(JSON.stringify(promptData));
    if (!newSeq?.sequence || !newSeq?.sequence?.length) {
      snackbar.error({
        title: "please select Objective First",
      });
      return;
    }

    var other_details = {};
    if (promptData?.sequence?.length) {
      other_details = promptData?.sequence[0];
    }
    let data = {
      ...promptData,
      sequence: [
        {
          ...other_details,
          model_version_sequence: v,
        },
      ],
    };

    dispatch(handleChangEditPrompt(data));
  };

  useEffect(() => {
    if (aiModelsList?.length == 0) {
      getAIModels({ page: 1, limit: 10 }).then((res) => {
        dispatch(setAIModelsList(res?.items));
      });
    }
    getObjectives().then((res) => {
      var failure_obj = {};
      res?.items?.map((ele) => {
        if (ele.context_key == "failure") {
          failure_obj = ele;
        }
      });
      dispatch(
        setObjectives([
          ...res?.items,
          {
            active: true,
            description: "",
            name: "NA",
            created_at: 1718874021,
            updated_at: 1718874021,
            objective_details: {},
            context_key: "NA",
            id: "NA",
          },
        ])
      );
    });
  }, []);

  const validatePromptSelect = () => {
    let hasErrors = false;
    let allErrors = [...promptData?.errors] || [];
    if (!promptData?.llmModel) {
      setErrors((ini) => ({ ...ini, llmModel: true }));
      hasErrors = true;
      allErrors.push({
        title: "LLM model",
        description: "Please select LLM model before saving prompt",
        type: "error",
      });
    }
    if (!promptData?.llmVersion) {
      setErrors((ini) => ({ ...ini, llmVersion: true }));
      hasErrors = true;
      allErrors.push({
        title: "LLM version model",
        description: "Please select LLM model before saving prompt",
        type: "error",
      });
    }
    if (promptData?.model_type === "Image") {
      if (!promptData?.imageSize) {
        setErrors((ini) => ({ ...ini, imageSize: true }));
        hasErrors = true;
        allErrors.push({
          title: "Image size",
          description: "Please select image size",
          type: "error",
        });
      }
    }

    if (promptData?.model_type === "Image") {
      if (!promptData?.processType) {
        setErrors((ini) => ({ ...ini, processType: true }));
        hasErrors = true;
        allErrors.push({
          title: "Process type model",
          description: "Please select process type",
          type: "error",
        });
      }
    } else {
      if (!promptData?.outputFormat) {
        let data = {
          ...promptData,
          activeScreen: "Response",
        };
        dispatch(handleChangEditPrompt(data));
        setErrors((ini) => ({ ...ini, outputFormat: true }));
        hasErrors = true;
        allErrors.push({
          title: "Response format",
          description: "Please select output format",
          type: "error",
        });
      }
    }
    if (promptList?.some((item) => !item?.action_val)) {
      allErrors.push({
        title: "Invalid Value",
        description: "You can not keep empty value parameter of prompt.",
        type: "warning",
      });
      hasErrors = true;
    }
    if (promptList?.some((item) => !item?.name)) {
      allErrors.push({
        title: "Invalid Key",
        description: "You can not keep empty key parameter of prompt.",
        type: "warning",
      });
      hasErrors = true;
    }
    let data = {
      ...promptData,
      errors: allErrors,
    };
    if (hasErrors) {
      snackbar.error({
        title: `${allErrors?.[0]?.title}  -- ${allErrors?.[0]?.description}`,
      });
    }
    dispatch(handleChangEditPrompt(data));
    return hasErrors;
  };

  const filterModelsVersions = (model, type) => {
    const versions = model?.versions?.filter(
      (item) => item?.model_type == type
    );
    dispatch(setModelsVersionsList(versions));
  };

  useEffect(() => {
    if (!emptyPromptData.llmModel && aiModelsList.length > 0) {
      const defaultModel = aiModelsList.find(
        (model) => model.name === "open_ai"
      );
      if (defaultModel) {
        setSelectedModel(defaultModel.id);
        setEmptyPromptData((ini) => ({
          ...ini,
          llmModel: defaultModel.id,
          llmModelName: defaultModel.name,
        }));

        const versions = defaultModel?.versions;
        if (versions) {
          dispatch(setModelsVersionsList(versions));
        }
      }
    }

    if (!emptyPromptData.llmVersion && modelsVersionsList.length > 0) {
      const defaultVersion = modelsVersionsList.find(
        (version) => version.name === "gpt-4o"
      );
      if (defaultVersion) {
        setSelectedVersion(defaultVersion.id);
        setEmptyPromptData((ini) => ({
          ...ini,
          llmVersion: defaultVersion.id,
          llmVersionName: defaultVersion.name,
        }));
      }
    }
  }, [
    aiModelsList,
    modelsVersionsList,
    emptyPromptData.llmModel,
    emptyPromptData.llmVersion,
  ]);

  const handleChangeInLlmModel = (event, isNew = true) => {
    const id = event.target.value;
    const model = aiModelsList?.find((model) => model?.id === id);

    if (model) {
      const llmModelName = model?.name;
      const versions = model?.versions;
      setSelectedModel(id);

      if (isNew) {
        setEmptyPromptData((ini) => ({
          ...ini,
          llmModel: id,
          llmModelName,
          model_type: "",
          llmVersion: "",
          llmVersionName: "",
        }));
        dispatch(setModelsVersionsList(versions));
      } else {
        const data = {
          ...promptData,
          llmModelName,
          llmModel: id,
          processType: "",
          llmVersion: "",
          llmVersionName: "",
          prompt_setting: emptyPrompt().prompt_setting,
        };

        dispatch(handleChangEditPrompt(data));
        filterModelsVersions(model, promptData?.model_type);
      }
    }
  };

  const handleChangeINLLmVersion = (event, isNew = true) => {
    const id = event.target.value;
    const version = modelsVersionsList?.find((version) => version?.id === id);

    if (version) {
      const llmVersionName = version.name;
      const model_type = version.model_type;

      let outputFormat = isNew
        ? emptyPrompt().outputFormat
        : promptData?.outputFormat;
      let output_keys = isNew
        ? emptyPrompt().output_keys
        : promptData?.output_keys;
      if (model_type === "Image") {
        outputFormat = "json";
        const imageOutputFormat = { data: [{ url: "string" }] };
        output_keys = {
          ...output_keys,
          json: imageOutputFormat,
        };
      }

      if (isNew) {
        setSelectedVersion(id);
        setEmptyPromptData((ini) => ({
          ...ini,
          llmVersion: id,
          llmVersionName,
          model_type,
          imageSize: "",
          outputFormat,
          output_keys,
          variationCount: 1,
        }));
      } else {
        const updatedPromptData = {
          ...promptData,
          llmVersion: id,
          llmVersionName,
          model_type,
          imageSize: "",
          outputFormat,
          output_keys,
          variationCount: 1,
          processType: "",
        };

        setSelectedVersion(id);
        dispatch(handleChangEditPrompt(updatedPromptData));
      }
    }
  };

  const handleChangeInObjective = (event) => {
    var other_details = {};
    if (promptData?.sequence?.length) {
      other_details = promptData?.sequence[0];
    }
    const selected_object = all_objectives.filter(
      (i) => i?.id === event.target.value
    )[0];
    let data = {
      ...promptData,
      sequence: [
        {
          ...other_details,
          objective_id: event.target.value,
          objective_name: selected_object.name,
          active: event.target.value == "NA" ? false : true,
        },
      ],
    };

    dispatch(handleChangEditPrompt(data));
  };

  const handleChangeInResponseTime = (event) => {
    var newSeq = { ...promptData };
    if (!newSeq?.sequence || !newSeq?.sequence?.length) {
      snackbar.error({
        title: "please select Objective First",
      });
      return;
    }
    var value = event?.target?.value ? event?.target?.value : event;
    var newvalue = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (newvalue == null) {
      snackbar.error({
        title: "Only numeric value allowed",
      });
      return;
    }

    var other_details = {};
    if (promptData?.sequence?.length) {
      other_details = promptData?.sequence[0];
    }
    let data = {
      ...promptData,
      sequence: [
        {
          ...other_details,
          objective_details: {
            response_time: event.target.value,
          },
        },
      ],
    };

    dispatch(handleChangEditPrompt(data));
  };

  const handleChangeInSequenceValue = (event, val) => {
    var value = event?.target?.value ? event?.target?.value : event;
    console.log(event?.target?.value ? event?.target?.value : event);
    if (event?.target?.value == "") {
      value = "";
    } else {
      if (val == "average_cnt" || val == "interval") {
        var newvalue = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
        if (newvalue == null) {
          snackbar.error({
            title: "Only numeric value allowed",
          });
          return;
        }
      }
    }
    var newSeq = { ...promptData };
    if (!newSeq?.sequence || !newSeq?.sequence?.length) {
      snackbar.error({
        title: "please select Objective First",
      });
      return;
    }

    var other_details = {};
    if (promptData?.sequence?.length) {
      other_details = promptData?.sequence[0];
    }

    let data = {
      ...promptData,
      sequence: [
        {
          ...other_details,
          [val]: val == "interval" ? value * 60 : value,
        },
      ],
    };

    dispatch(handleChangEditPrompt(data));
  };
  const handleChangeProcessType = (event) => {
    let data = {
      ...promptData,
      processType: event.target.value,
    };
    dispatch(handleChangEditPrompt(data));
  };
  const handleChangeVariation = (event) => {
    let output_keys = promptData?.output_keys;
    let imageOutputFormat = { data: [] };
    let data = [];
    for (let index = 0; index < event.target.value; index++) {
      data.push({ url: "string" });
    }
    imageOutputFormat["data"] = data;
    output_keys = {
      ...promptData.output_keys,
      json: imageOutputFormat,
    };
    let data1 = {
      ...promptData,
      variationCount: event.target.value,
      output_keys,
    };
    dispatch(handleChangEditPrompt(data1));
  };

  const handleChangeSize = (event) => {
    let data = {
      ...promptData,
      imageSize: event.target.value,
    };
    dispatch(handleChangEditPrompt(data));
  };
  const handleChangeEnvironment = (event) => {
    let data = {
      ...promptData,
      environment_id: event.target.value,
      // variables: {},
    };
    dispatch(handleChangEditPrompt(data));
  };

  const handleClosePrompt = (e, prompt) => {
    e.preventDefault();

    setDiscardPromptId(prompt?.id);
    setOpenDiscardPrompt(true);
  };

  const handleDiscardPrompt = (e) => {
    e.preventDefault();

    if (discardPromptId) {
      dispatch(removeFromEditPrompt(discardPromptId));
      setOpenDiscardPrompt(false);
      setShowChangeModal(false);
      setDiscardPromptId(null);
    }
  };

  const validateNewPrompt = () => {
    let hasError = false;
    if (!emptyPromptData?.llmModel) {
      //setErrors((ini) => ({ ...ini, llmModel: true }));
      hasError = true;
    }
    if (!emptyPromptData?.llmVersion) {
      //setErrors((ini) => ({ ...ini, llmVersion: true }));
      hasError = true;
    }
    if (!emptyPromptData?.name) {
      //setErrors((ini) => ({ ...ini, name: true }));
      hasError = true;
    }
    if (!emptyPromptData?.prompt_text?.prompt_data?.length) {
      //setErrors((ini) => ({ ...ini, prompt: true }));
      hasError = true;
    } else {
      if (!emptyPromptData?.prompt_text?.prompt_data[0]?.action_val) {
        //setErrors((ini) => ({ ...ini, prompt: true }));
        hasError = true;
      }
    }
    return hasError;
  };

  const handleSetActiveTab = (e, prompt) => {
    if (validateNewPrompt()) {
      return;
    }
    dispatch(addToEditPrompt(prompt));
    setEmptyPromptData({});
  };
  const handleChangeActiveTab = (e, prompt) => {
    dispatch(addToEditPrompt(prompt));
    const model = aiModelsList?.filter(
      (model) => model?.id === prompt?.llmModel
    )[0];
    filterModelsVersions(model, prompt?.model_type);
    setShowAddPromptScreen(false);
    setShowChangeModal(false);
    setErrors({
      llmModel: false,
      llmVersion: false,
      outputFormat: false,
      processType: false,
    });
    e.preventDefault();
  };
  const handleStatusCall = (prompt_data, req_id) => {
    getPromptDataAnalytics(req_id)
      .then((res) => {
        if (res.status == "recall") {
          setTimeout(() => {
            handleStatusCall(prompt_data, req_id);
          }, 1000);
        } else if (res.status == "success") {
          let data = {
            ...prompt_data,

            response_id: res.response_id || "",
            response_state_result: res.status,
            prompt_cost: res?.cost,
            prompt_token: res?.tokens,
            prompt_output_tokens: res?.output_tokens,
            prompt_input_tokens: res?.input_tokens,
            prompt_response_time: res?.response_time,
          };

          dispatch(handleChangEditPrompt(data));
        } else {
          alert("res id failed");
          let data = {
            ...promptData,
            response_state_result: "Failed",
          };

          dispatch(handleChangEditPrompt(data));
        }
      })
      .catch((error) => {
        snackbarService.error({
          title: `${error.error && error.error.toString()}`,
        });
      })
      .finally(() => {});
  };
  const formatOutputInJson = (res) => {
    if (res?.items?.response) {
      if (Array.isArray(res?.items?.response)) {
        if (res?.items?.response.length) {
          if ("data" in res?.items?.response[0]) {
            if (Array.isArray(res?.items?.response[0]["data"])) {
              if (res?.items?.response[0]["data"].length) {
                try {
                  let promptOutput = {};
                  if (res?.items?.response[0]["data"]?.length === 1) {
                    promptOutput = JSON.parse(
                      res?.items?.response[0]["data"][0]
                    );
                  } else if (res?.items?.response[0]["data"]?.length > 1) {
                    promptOutput = res?.items?.response[0]["data"].map((item) =>
                      JSON.parse(item)
                    );
                  }

                  if (res?.content_type === "Image") {
                    promptOutput = res?.items?.response[0]["data"].map((item) =>
                      JSON.parse(item)
                    );
                  }

                  let data = {
                    ...promptData,
                    promptOutput: promptOutput,
                    content_type: res?.content_type || "Text",
                    activeScreen: "Response",
                    expandFormatter: false,
                    output_format: res.output_format || "json",
                    response_id: res.response_id || "",
                    response_state_result: "recall",
                  };
                  if (!!res?.items?.conversation_id) {
                    data["conversation_id"] = res?.items?.conversation_id;
                  }
                  dispatch(handleChangEditPrompt(data));
                  handleStatusCall(data, res.response_id);
                } catch (error) {
                  let data = {
                    ...promptData,
                    promptOutput: res?.items?.response[0]["data"][0],
                    content_type: res?.content_type,
                  };
                  dispatch(handleChangEditPrompt(data));
                  snackbarService.error({
                    title: `Response not in valid json Formate`,
                  });
                }
              }
            }
          }
        }
      }
    }
  };

  const formatOutputInCsv = (res) => {
    try {
      let toJson = readString(res?.items?.response[0]["data"][0], {});
      let arrays = toJson?.data;
      let keys = arrays[0];

      if (!keys || keys.length === 0) {
        throw new Error("CSV headers are missing or empty");
      }

      const result = [];
      for (let i = 1; i < arrays.length; i++) {
        const obj = {};
        for (let j = 0; j < keys.length; j++) {
          obj[keys[j]] = arrays[i][j] || "";
        }
        result.push(obj);
      }

      let data = {
        ...promptData,
        promptOutput: result,
        content_type: res?.content_type,
        activeScreen: "Response",
        expandFormatter: false,
        output_format: res.output_format,
        isValidCsvFormat: true,
      };
      dispatch(handleChangEditPrompt(data));
    } catch (error) {
      if (error.message.includes("CSV headers are missing")) {
        snackbar.error({
          title: "Invalid CSV format",
          message: error.message,
        });
      }
      console.log("CSV format error:", error);
    }
  };

  const formatOutputInXml = (res) => {
    const isValid = isValidXML(res?.items?.response[0]["data"][0]);
    if (!isValid) {
      snackbar.error({
        title: "Invalid Xml format",
      });
    }

    let data = {
      ...promptData,
      promptOutput: res?.items?.response[0]["data"][0],
      content_type: res?.content_type,
      activeScreen: "Response",
      expandFormatter: false,
      output_format: res.output_format,
      isValidXmlFormat: isValid,
    };
    dispatch(handleChangEditPrompt(data));
  };

  const handleRunPromptHandler = async () => {
    if (validatePromptSelect()) return;
    dispatch(handleLoading(true));
    let data = {
      llm_version: promptData?.llmVersion,
      prompt_text: {
        prompt_data: promptList,
      },
      prompt_setting: promptData?.prompt_setting,
      variation_count: promptData?.variationCount,
      output_language: promptData?.output_language || "English",
      prompt_id: promptData?.id,
    };
    if (!!promptData?.environment_id) {
      data["environment_id"] = promptData?.environment_id;
    }
    if (!!promptData?.context) {
      data["context"] = promptData?.context;
    }
    if (
      (promptData?.processType === "edit" ||
        promptData?.processType === "create") &&
      promptData?.model_type === "Image"
    ) {
      data["process_type"] = promptData?.processType;
      data["size"] = promptData?.imageSize;
      if (promptData?.processType === "edit") {
        if (!promptData?.image_data || !promptData?.mask_image) {
          snackbarService.warning({
            title: "Please upload image or add image url",
          });
          dispatch(handleLoading(false));
          return;
        }
        if (isValidURL(promptData?.image_data)) {
          data["image_data"] = promptData?.image_data;
        } else if (!!promptData?.image_data) {
          data["image_data"] = await convertFileToBase64(
            promptData?.image_data
          );
        }
        if (isValidURL(promptData?.mask_image)) {
          data["mask_image"] = promptData?.mask_image;
        } else if (promptData?.mask_image) {
          data["mask_image"] = await convertFileToBase64(
            promptData?.mask_image
          );
        }
      }
    } else {
      data["prompt_setting"] = promptData?.prompt_setting;
      data["output_format"] = promptData?.outputFormat;
      let outputKeys =
        formatOutputKeysToSave(promptData)[promptData?.outputFormat];
      data["output_keys"] = outputKeys;
      if (promptData?.outputFormat === "xml") {
        if (!isValidXML(outputKeys)) {
          snackbar.warning({ title: "Please add valid XML format" });
          dispatch(handleLoading(false));
          return;
        }
      }
      data["process_type"] = "text";
    }

    const payload = {
      team_id: activeTeam,
      data,
    };
    if (!!promptData?.variables) {
      var all_variable = [];
      promptData?.variables.map((elem) => {
        var ele = {
          variable_key: elem.variable_key,
          description: elem?.description,
          type: elem.type,
          label: elem.label,
        };
        if (elem.type === "file") {
          ele["file_id"] = elem?.file_id;
          ele["transformer_id"] = elem?.transformer_id;
          if ("question_query" in elem) {
            ele["question_query"] = elem?.question_query;
          }
        }
        all_variable.push(ele);
      });
      payload["data"]["variables"] = all_variable;
    }

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    executePrompt(payload)
      .then((res) => {
        const outputFunctions = {
          json: formatOutputInJson,
          csv: formatOutputInCsv,
          xml: formatOutputInXml,
        };
        outputFunctions[res?.output_format || "json"](res);
      })
      .catch((error) => {
        console.log(error);
        snackbarService.error({
          title: `${error.error && error.error.toString()}`,
        });
        if (error?.status == 429) {
          setOpenTokenExpire(true);
        }
      })
      .finally(() => {
        dispatch(handleLoading(false));
        dispatch(fetchAvailableCredits({ team_id: activeTeam }));
      });
  };

  const handleDispatchPromptName = (e) => {
    let data = {
      ...promptData,
      name: e.target.value,
    };
    dispatch(handleChangEditPrompt(data));
  };

  const savePromptHandler = async () => {
    if (validatePromptSelect()) return;

    let payloadFetch = {
      collection_id: params?.collectionId,
      params: {
        page: allPromptsData?.page || 1,
        limit: 10,
      },
    };
    let outputKeys = formatOutputKeysToSave(promptData);
    if (promptData?.outputFormat === "xml") {
      if (!isValidXML(promptData?.output_keys["xml"])) {
        snackbar.warning({ title: "Please add valid XML format" });
        dispatch(handleLoading(false));
        return;
      }
    }

    let data = {
      name: promptData?.name,
      prompt_text: {
        prompt_data: promptData?.prompt_text?.prompt_data,
      },
      tags: promptData?.tags,
      prompt_data: promptData?.prompt_data,
      category: "base",
      llm_version_id: promptData?.llmVersion,
      output_format: promptData?.outputFormat,
      output_keys: outputKeys,
      variation_count: promptData?.variationCount,
      cache_setting: promptData?.cache_setting,
      output_language: promptData?.output_language || "English",
    };

    if (!!promptData?.environment_id) {
      data["environment_id"] = promptData?.environment_id;
    }
    if (!!promptData?.description) {
      data["description"] = promptData?.description;
    }
    if (!!promptData?.context) {
      data["context"] = promptData?.context;
    }
    if (
      (promptData?.processType === "edit" ||
        promptData?.processType === "create") &&
      promptData?.model_type === "Image"
    ) {
      data["size"] = promptData?.imageSize;
      data["prompt_type"] = promptData?.processType;
    } else {
      data["prompt_type"] = "text";
      data["prompt_setting"] = promptData?.prompt_setting;
    }

    let payload = {
      collection_id: params?.collectionId,
      data,
    };
    if (!!promptData?.prompt_data) {
      payload["data"]["prompt_data"] = promptData?.prompt_data;
    }
    if (!!promptData?.variables) {
      var all_variable = [];
      promptData?.variables.map((elem) => {
        var ele = {
          variable_key: elem.variable_key,
          description: elem?.description,
          type: elem.type,
          label: elem.label,
        };
        if (elem.type === "file") {
          ele["file_id"] = elem?.file_id;
          ele["transformer_id"] = elem?.transformer_id;
          if ("question_query" in elem) {
            ele["question_query"] = elem?.question_query;
          }
        }
        all_variable.push(ele);
      });
      payload["data"]["variables"] = all_variable;
    }
    let successMessage = "";
    let promptApi = undefined;
    if (promptData?.id?.includes("newPromptId")) {
      promptApi = savePrompt;
      successMessage = `Prompt Added`;
    } else {
      payload = { ...payload, prompt_id: promptData.id };
      promptApi = updatePrompt;
      successMessage = "Prompt updated";
    }

    if (promptData?.sequence?.length) {
      payload["data"]["sequence"] = [
        {
          ...promptData.sequence[0],
        },
      ];
      var inerr = parseInt(promptData.sequence[0].interval);
      if (inerr == null || inerr == "null" || isNaN(inerr)) {
        inerr = 60;
      }
      debugger;
      payload["data"]["sequence"][0]["interval"] = inerr;
      payload["data"]["sequence"][0]["average_cnt"] = parseInt(
        promptData.sequence[0].average_cnt
      );
      var newModelSwq = [];
      if ("model_version_sequence" in promptData.sequence[0]) {
        promptData.sequence[0]["model_version_sequence"].map((ele, index) => {
          newModelSwq.push({
            model_id: ele.id,
            sequence_no: index + 1,
          });
        });
        payload["data"]["sequence"][0]["model_version_sequence"] = [
          ...newModelSwq,
        ];
      }
      if ("objective_id" in payload["data"]["sequence"][0]) {
        if (payload["data"]["sequence"][0]["objective_id"] == "NA") {
          var failure_obj1 = {};
          all_objectives.map((ele) => {
            if (ele.context_key == "failure") {
              failure_obj1 = ele;
            }
          });
          payload["data"]["sequence"][0]["objective_id"] = failure_obj1.id;
          payload["data"]["sequence"][0]["active"] = false;
        } else {
          payload["data"]["sequence"][0]["active"] =
            promptData.sequence[0].active == "true" ||
            promptData.sequence[0].active == true
              ? true
              : false;
        }
      }
      if ("objective_name" in payload["data"]["sequence"][0]) {
        delete payload["data"]["sequence"][0]["objective_name"];
      }

      if (promptData?.sequence?.[0]?.objective_details?.response_time) {
        var valueDataNum = parseInt(
          promptData?.sequence?.[0]?.objective_details?.response_time
        );
        payload["data"]["sequence"][0]["objective_details"] = {
          response_time: valueDataNum,
        };
      }
    }

    dispatch(handleLoading(true));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    const promises = [promptData].map(async () => {
      try {
        const res = await promptApi(payload);
        let outputKeys = formatOutputKeysToEdit(res);

        if (successMessage === "Prompt Added") {
          let data = {
            oldId: promptData?.id,
            ...promptData,
            ...res,
            output_keys: outputKeys,
          };
          dispatch(handleUpdatePromptId(data));
          snackbarService.success({
            title: `Prompt added successfully`,
          });
        } else {
          let data = {
            ...promptData,
            ...res,
            output_keys: outputKeys,
          };

          dispatch(handleChangEditPrompt(data));
          snackbarService.success({
            title: `Prompt updated successfully`,
          });
        }
      } catch (error) {
        console.log(error);
        snackbarService.error({
          title: `${error.error && error.error.toString()}`,
        });
      } finally {
        dispatch(handleLoading(false));
      }
    });
    await Promise.all(promises);

    await dispatch(fetchPrompts(payloadFetch));
  };

  const confirmChangeModel = () => {
    if (!promptData?.llmModel) {
      setErrors((ini) => ({ ...ini, llmModel: true }));
      return;
    }
    if (!promptData?.llmVersion) {
      setErrors((ini) => ({ ...ini, llmVersion: true }));
      return;
    }
    setShowChangeModal(false);
  };

  const confirmObjectiveChangeModel = () => {
    if (
      !promptData?.sequence?.length ||
      !promptData?.sequence?.[0]?.objective_id
    ) {
      setErrors((ini) => ({ ...ini, objective_id: true }));
      return;
    }
    if (promptData?.sequence[0]?.objective_name == "uptime") {
      if (!promptData?.sequence?.[0]?.objective_details?.response_time) {
        setErrors((ini) => ({ ...ini, res_time: "select response time" }));
        return;
      }
    }
    if (!promptData?.sequence?.[0]?.model_version_sequence?.length) {
      snackbarService.error({
        title: `You need to select at least one model to perform object`,
      });
      return;
    }

    if (!promptData?.sequence?.[0]?.interval) {
      // setErrors((ini) => ({ ...ini, interval: "select interval" }));
      // return;
      var ddd = { target: { value: "1" } };
      handleChangeInSequenceValue(ddd, "interval");
    }
    // else {
    //   var newvalue = promptData?.sequence?.[0]?.interval.match(
    //     /^(\d*\.{0,1}\d{0,2}$)/
    //   );
    //   if (newvalue == null) {
    //     snackbar.error({
    //       title: "Only numeric value allowed in interval",
    //     });
    //     return;
    //   }
    // }
    if (!promptData?.sequence?.[0]?.algorithm) {
      // setErrors((ini) => ({ ...ini, algorithm: "select algorithm" }));
      // return;
      handleChangeInSequenceValue("random", "algorithm");
    }
    if (!promptData?.sequence?.[0]?.average_cnt) {
      // setErrors((ini) => ({ ...ini, average_cnt: "select average count" }));
      // return;
      handleChangeInSequenceValue("1", "average_cnt");
    }
    // else {
    //   var ddddds = promptData?.sequence?.[0]?.average_cnt;
    //   alert(ddddds);
    //   var newvalue = ddddds.match(/^(\d*\.{0,1}\d{0,2}$)/);
    //   if (newvalue == null) {
    //     snackbar.error({
    //       title: "Only numeric value allowed prompt failed",
    //     });
    //     return;
    //   }
    // }

    setSelectedTab("Preferred Model");
  };
  const muiSelectFormControlSx = {
    color: mode === "dark" ? colors.textSecondary : colors.brandPrimary,
    "& .MuiFormLabel-root": {
      color: mode === "dark" ? colors.textSecondary : colors.brandPrimary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor:
          mode === "dark"
            ? "rgba(206, 207, 210, 0.5)"
            : "rgba(105, 56, 239, 0.5)",
      },
      "&:hover fieldset": {
        borderColor: colors.brandPrimary,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.brandPrimary,
      },
    },
  };

  const handleTabChange = (tab) => {
    let data = {
      ...promptData,
      activeScreen: tab,
    };
    if (
      tab === "Compare" &&
      Object.keys(promptData?.compareResultResponse || {}).length === 0
    ) {
      let compare_data = [];
      for (let index = 0; index < 2; index++) {
        compare_data.push({
          model_version_id: index === 0 ? promptData?.llmVersion : null,
          temperature: promptData?.prompt_setting?.temperature,
          max_tokens: promptData?.prompt_setting?.max_token,
          variation_cnt: promptData?.variationCount,
          context_id: promptData?.context,
          llmModel: promptData?.llmModel,
          aiModelsList: aiModelsList,
          modelsVersionsList: modelsVersionsList,
        });
      }
      data["compare_data"] = compare_data;
      data["iteration_cnt"] = 1;
    }
    dispatch(handleChangEditPrompt(data));
  };

  const HandlePromptObjectiveChange = (tab) => {
    setSelectedTab(tab);
  };

  let iterationInterval;

  const callIterations = async ({ comparison_id, model_compare_id }) => {
    try {
      const res = await getAllIterationData({
        comparison_id,
        model_compare_id,
        params: { page: 1, limit: promptData?.iteration_cnt || 10 },
      });

      return res;
    } catch (error) {
      console.error(error);
      snackbarService.error({ title: `${error.error?.toString()}` });
      iterationInterval && clearInterval(iterationInterval);
      const updatedData = {
        ...promptData,
        comparisonProgress: false,
        compareResultResponse: {},
        iterationResponse: [],
      };
      dispatch(handleChangEditPrompt(updatedData));
      return false;
    }
  };

  const callComparisonResults = async ({ comparison_id }) => {
    try {
      const res = await getComparisonResult({
        comparison_id,
        params: {
          page: 1,
        },
      });
      snackbarService.success({
        title: `Prompt comparison completed for prompt ${promptData?.name}`,
      });
      return res;
    } catch (error) {
      console.error(error);
      snackbarService.error({ title: `${error.error?.toString()}` });
      return false;
    }
  };

  const handleResetCompare = () => {
    const data = {
      ...promptData,
      compareResponse: {},
      compareResultResponse: {},
      iterationResponse: [],
      comparisonProgress: false,
    };
    dispatch(handleChangEditPrompt(data));
  };

  const handleSelectExample = (example) => {
    setEmptyPromptData({
      ...emptyPromptData,
      name: example.name,
      prompt_text: {
        prompt_data: [
          {
            name: "action",
            action_val: example.text,
          },
        ],
      },
    });
  };

  return (
    <>
      <Popper
        id={showChangeModal ? "simple-popper" : undefined}
        anchorEl={showChangeModal}
        open={!!showChangeModal}
        handleClose={() => setShowChangeModal(false)}
        sx={{ zIndex: "1000", marginLeft: "3rem" }}
      >
        <div
          className="p-4 flex flex-col gap-4 rounded-lg bg-secondary border-1 border-primary"
          style={{ width: "400px", marginLeft: "3rem" }}
        >
          <div className="flex items-center justify-center my-4">
            <div className="flex gap-4 p-1 rounded-full  bg-quaternary">
              {["Preferred Model", "Objective"]?.map((tab) => (
                <div
                  className={`text-sm-semibold px-4 py-2 cursor-pointer  ${
                    selectedTab == tab
                      ? "bg-secondary text-brandPrimary-light dark:text-textPrimary border-1 border-secondary rounded-full"
                      : "text-tertiary "
                  }`}
                  key={tab}
                  onClick={() => HandlePromptObjectiveChange(tab)}
                >
                  {tab}
                </div>
              ))}
            </div>
          </div>
          {selectedTab == "Preferred Model" ? (
            <div className="w-full flex flex-col gap-2">
              <div className="">
                <MuiSelect
                  label={"Model"}
                  menuItems={
                    aiModelsList?.filter(({ versions }) =>
                      versions?.some(
                        ({ model_type }) =>
                          model_type === promptData?.model_type
                      )
                    ) || []
                  }
                  value={promptData?.llmModel}
                  onChange={(e) => handleChangeInLlmModel(e, false)}
                  error={errors?.llmModel}
                  onFocus={() =>
                    setErrors((ini) => ({ ...ini, llmModel: false }))
                  }
                  valueKey={"id"}
                  labelKey={"name"}
                />
              </div>
              <div className="mt-2">
                <MuiSelect
                  label={"Version"}
                  menuItems={modelsVersionsList}
                  value={promptData?.llmVersion}
                  onChange={(e) => handleChangeINLLmVersion(e, false)}
                  disabled={!promptData?.llmModel}
                  error={errors?.llmVersion}
                  onFocus={() =>
                    setErrors((ini) => ({ ...ini, llmVersion: false }))
                  }
                  valueKey={"id"}
                  labelKey={"name"}
                />
              </div>{" "}
              <div className="flex justify-end">
                <Button variant="contained" onClick={confirmChangeModel}>
                  Confirm
                </Button>
              </div>
            </div>
          ) : (
            <div className="w-full flex flex-col gap-2">
              <div className="">
                <label className="text-secondary text-sm font-medium mb-1">
                  Objective
                </label>
                <MuiSelect
                  // label={"Objectives"}
                  menuItems={all_objectives}
                  value={
                    promptData?.sequence?.length
                      ? promptData?.sequence?.[0]?.active == false ||
                        promptData?.sequence?.[0]?.active == "false"
                        ? "NA"
                        : promptData?.sequence?.[0]?.objective_id
                        ? promptData?.sequence?.[0]?.objective_id
                        : ""
                      : ""
                  }
                  onChange={(e) => {
                    handleChangeInObjective(e);
                  }}
                  // disabled={!promptData?.objective_id}
                  error={errors?.objective_id}
                  onFocus={() =>
                    setErrors((ini) => ({ ...ini, objective_id: false }))
                  }
                  valueKey={"id"}
                  labelKey={"name"}
                />
              </div>
              {promptData?.sequence?.length &&
              promptData?.sequence[0]?.objective_name == "uptime" ? (
                <div className="w-full">
                  <FilledTextField
                    label="Response time (sec)"
                    fullWidth
                    style={{
                      background: mode === "dark" ? "#161B26" : "white",
                    }}
                    type={"text"}
                    value={
                      promptData?.sequence?.length
                        ? promptData?.sequence?.[0]?.objective_details
                            ?.response_time
                          ? promptData?.sequence?.[0]?.objective_details
                              ?.response_time
                          : ""
                        : ""
                    }
                    onChange={(e) => handleChangeInResponseTime(e)}
                    placeholder={"Enter response time"}
                    error={errors.res_time}
                    helperText={errors.res_time}
                    onFocus={() =>
                      setErrors((ini) => ({ ...ini, res_time: "" }))
                    }
                  />
                </div>
              ) : null}
              <div className="">
                <label className="text-secondary text-sm font-medium mb-1">
                  Select model sequence to selected objective
                </label>
                <Autocomplete
                  clearIcon={false}
                  options={
                    aiModelsList?.reduce((total, { versions }) => {
                      versions?.map((element) => {
                        if (element?.model_type === promptData?.model_type) {
                          var found = false;
                          if (
                            promptData?.sequence?.[0]?.model_version_sequence
                              ?.length
                          ) {
                            promptData?.sequence?.[0]?.model_version_sequence?.map(
                              (ele) => {
                                if (ele.id == element.id) {
                                  found = true;
                                }
                              }
                            );
                          }
                          if (!found) total.push({ ...element });
                        }
                      });
                      return total;
                    }, []) || []
                  }
                  filterSelectedOptions
                  getOptionLabel={(option) =>
                    option?.name ? option?.name : ""
                  }
                  size="small"
                  multiple
                  value={
                    promptData?.sequence?.[0]?.model_version_sequence
                      ? promptData?.sequence?.[0]?.model_version_sequence
                      : []
                  }
                  onChange={(event, newval) => {
                    handleAssetsChange(newval);
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      placeholder={"Select Models"}
                      {...params}
                    />
                  )}
                />
              </div>
              <div className=" pb-2 hidden">
                <FormControlLabel
                  control={<MuiCheckbox />}
                  label={`Current Objective is active `}
                  className="text-sm text-tertiary"
                  checked={
                    promptData?.sequence?.length
                      ? promptData?.sequence?.[0]?.active
                        ? promptData?.sequence?.[0]?.active
                        : false
                      : false
                  }
                  value={
                    promptData?.sequence?.length
                      ? promptData?.sequence?.[0]?.active
                        ? promptData?.sequence?.[0]?.active
                        : false
                      : false
                  }
                  onChange={(e) =>
                    handleChangeInSequenceValue(e.target.checked, "active")
                  }
                  // onChange={(e) => console.log(e.target.checked)}
                />
              </div>{" "}
              <Accordion
                sx={{
                  // backgroundColor: mode === "dark" ? "#053321" : "#ECFDF3",
                  // border:
                  //   mode === "dark"
                  //     ? "1px solid #085D3A"
                  //     : "1px solid #ABEFC6",
                  boxShadow: "none",
                  backgroundColor: mode === "dark" ? "#161B26" : "white",
                  backgroundImage: "none",
                  padding: "0px",
                  borderTop: "0px solid black",
                  "&.MuiAccordion-root:before": {
                    backgroundColor: "white",
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                  sx={{
                    padding: "0px",
                  }}
                >
                  <div className="text-lg-semibold">Advance settings</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="w-full  mb-1">
                    <FilledTextField
                      label="Reset interval in hours"
                      fullWidth
                      // type={"number"}
                      value={
                        promptData?.sequence?.length
                          ? promptData?.sequence?.[0]?.interval
                            ? promptData?.sequence?.[0]?.interval / 60
                            : 1
                          : 1
                      }
                      onChange={(e) => {
                        handleChangeInSequenceValue(e, "interval");
                      }}
                      placeholder={""}
                      error={errors.interval}
                      helperText={errors.interval}
                      type="text"
                      onFocus={() =>
                        setErrors((ini) => ({ ...ini, interval: "" }))
                      }
                    />
                  </div>
                  <div className="w-full my-3">
                    <FilledTextField
                      label="Execute objective after number of prompt failed"
                      fullWidth
                      type={"text"}
                      value={
                        promptData?.sequence?.length
                          ? promptData?.sequence?.[0]?.average_cnt
                            ? promptData?.sequence?.[0]?.average_cnt
                            : 1
                          : 1
                      }
                      onChange={(e) =>
                        handleChangeInSequenceValue(e, "average_cnt")
                      }
                      placeholder={""}
                      error={errors.average_cnt}
                      helperText={errors.average_cnt}
                      onWheel={(e) => e.target.blur()}
                      onFocus={() =>
                        setErrors((ini) => ({ ...ini, average_cnt: "" }))
                      }
                    />
                  </div>{" "}
                  <label className="text-secondary text-sm font-medium mb-1 mt-3">
                    Select algorithm
                  </label>
                  <MuiSelect
                    label={""}
                    menuItems={["round_robin", "random"]}
                    selectClassName={"bg-primary"}
                    value={
                      promptData?.sequence?.[0]?.algorithm
                        ? promptData?.sequence?.[0]?.algorithm
                        : "random"
                    }
                    onChange={(v) => {
                      handleChangeInSequenceValue(v, "algorithm");
                    }}
                    menuItemClass={""}
                    error={errors?.algorithm}
                    onFocus={() =>
                      setErrors((ini) => ({ ...ini, algorithm: false }))
                    }
                  />
                </AccordionDetails>
              </Accordion>
              <div className="flex justify-end">
                <Button
                  variant="contained"
                  onClick={confirmObjectiveChangeModel}
                >
                  Confirm
                </Button>
              </div>
            </div>
          )}
        </div>
      </Popper>
      <TokenExpire
        open={openTokenExpire}
        onClose={() => setOpenTokenExpire(false)}
      />
      <AppTabs
        list={editPromptsList}
        onBlur={handleDispatchPromptName}
        handleAddNew={(e) => {
          setShowAddPromptScreen(true);
          setShowChangeModal(false);
        }}
        handleCloseTab={handleClosePrompt}
        handleSetActiveTab={handleChangeActiveTab}
        isPreviewMode={isPreviewMode}
      />
      {showAddPromptScreen && !isPreviewMode ? (
        <div className="relative text-4xl text-center  w-full bg-primary -mt-8">
          <div className="overflow-hidden w-full h-full">
            <div className="overflow-hidden absolute top-10 left-1/2 -translate-x-1/2 ">
              <FileTypeCircleIcon />
            </div>

            <div className=" w-[50%] m-auto absolute top-40 left-1/2 -translate-x-1/2 ">
              <p className="text-sm-semibold  text-primary  ">
                Nothing to see here
              </p>
              <p className="text-sm text-tertiary ">
                Provide the details below to get started.
              </p>
              <div className="my-2 flex items-center justify-center gap-4">
                <div className="w-40">
                  <MuiSelect
                    label={"Model"}
                    menuItems={aiModelsList}
                    value={selectedModel}
                    onChange={handleChangeInLlmModel}
                    error={errors?.llmModel}
                    onFocus={() =>
                      setErrors((ini) => ({ ...ini, llmModel: false }))
                    }
                    valueKey={"id"}
                    labelKey={"name"}
                    selectClassName={"bg-primary"}
                  />
                </div>
                <div className="w-40">
                  <MuiSelect
                    label={"Version"}
                    menuItems={modelsVersionsList}
                    value={selectedVersion}
                    onChange={handleChangeINLLmVersion}
                    disabled={!emptyPromptData?.llmModel}
                    error={errors?.llmVersion}
                    onFocus={() =>
                      setErrors((ini) => ({ ...ini, llmVersion: false }))
                    }
                    valueKey={"id"}
                    labelKey={"name"}
                    selectClassName={"bg-primary"}
                  />
                </div>
              </div>
              <div className="bg-secondary border-1  border-secondary rounded-lg p-4 flex flex-col gap-1">
                <div className=" flex flex-wrap gap-4 ">
                  {examples.map((example, index) => (
                    <div
                      key={index}
                      className="bg-primary text-white p-2 flex items-center rounded-lg border border-secondary cursor-pointer hover:bg-gray-800"
                      onClick={() => handleSelectExample(example)}
                    >
                      <StarBorderIcon sx={{ height: "16px" }} />
                      <h2 className="text-xs text-primary whitespace-nowrap overflow-hidden text-ellipsis ">
                        {example.name}
                      </h2>
                    </div>
                  ))}
                </div>
                <div className="flex justify-start flex-col">
                  <span className="text-secondary text-[10px] text-left">
                    Name
                  </span>
                  <Textarea
                    label={""}
                    minRows={1}
                    maxRows={1}
                    value={emptyPromptData.name || ""}
                    style={{ background: colors.bgPrimary, padding: "0px" }}
                    placeholder={"Enter prompt name"}
                    error={errors?.name}
                    onFocus={() =>
                      setErrors((ini) => ({ ...ini, name: false }))
                    }
                    onChange={(e) => {
                      setEmptyPromptData((ini) => ({
                        ...ini,
                        name: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="flex justify-start flex-col">
                  <span className="text-secondary text-[10px] text-left">
                    Prompt
                  </span>
                  <Textarea
                    label={""}
                    minRows={2}
                    maxRows={2}
                    value={
                      emptyPromptData.prompt_text?.prompt_data[0]?.action_val ||
                      ""
                    }
                    style={{ background: colors.bgPrimary, padding: "0px" }}
                    placeholder={"Write your prompt"}
                    error={errors?.prompt}
                    onFocus={() =>
                      setErrors((ini) => ({ ...ini, prompt: false }))
                    }
                    onChange={(e) => {
                      setEmptyPromptData((ini) => ({
                        ...ini,
                        prompt_text: {
                          prompt_data: [
                            {
                              name: "action",
                              action_val: e.target.value,
                            },
                          ],
                        },
                      }));
                    }}
                  />
                </div>
                <div className="flex justify-end mt-3">
                  <Button
                    variant="contained"
                    onClick={(e) =>
                      handleSetActiveTab(e, {
                        ...emptyPrompt(),
                        ...emptyPromptData,
                      })
                    }
                    disabled={validateNewPrompt()}
                  >
                    Create prompt
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : showAddPromptScreen && isPreviewMode ? (
        <div className=" w-full h-full flex flex-col items-center justify-center ">
          <div className="flex justify-center flex-col items-center gap-5">
            <FileTypeCircleIcon />
            <p className="text-sm-semibold  text-primary  ">
              Select prompt from list to preview
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div className="pb-2 w-full pt-4 border-b-1 border-primary flex items-center justify-between px-4 sticky top-[39.5px] bg-tertiary z-10 overflow-auto">
            <div>
              {promptData?.activeScreen !== "Compare" && (
                <Button
                  aria-describedby={
                    showChangeModal ? "simple-popper" : undefined
                  }
                  onClick={(e) => {
                    setSelectedTab("Preferred Model");
                    setShowChangeModal(e.currentTarget);
                  }}
                  className={`flex gap-2 items-center cursor-pointer `}
                  endIcon={
                    showChangeModal ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )
                  }
                  startIcon={
                    <span className="h-auto">
                      {llmModelsIcon()[promptData?.llmModelName] &&
                        !showChangeModal &&
                        llmModelsIcon("20px")[promptData?.llmModelName]}
                    </span>
                  }
                  color={errors.llmVersion ? "error" : "secondary"}
                  variant={errors.llmVersion ? "outlined" : "text"}
                  sx={{
                    borderColor:
                      errors.llmVersion && !showChangeModal && "#F04438",
                    color: colors.textPrimary,
                  }}
                  title={`${promptData?.llmModelName} - ${promptData?.llmVersionName}`}
                >
                  <span className="text-primary text-base w-20 text-ellipsis overflow-hidden whitespace-nowrap ">
                    {showChangeModal
                      ? "Select LLM"
                      : `${promptData?.llmVersionName}`}
                  </span>
                </Button>
              )}
            </div>
            <div className="w-full flex items-center justify-end gap-2">
              {!!promptData?.llmVersion &&
                promptData?.model_type === "Image" && (
                  <div className="w-32">
                    <MuiSelect
                      label={"Process"}
                      menuItems={["create", "edit"]}
                      value={promptData?.processType}
                      onChange={handleChangeProcessType}
                      disabled={!promptData?.llmVersion}
                      error={errors?.processType}
                      menuItemClass={"capitalize"}
                      onFocus={() =>
                        setErrors((ini) => ({ ...ini, processType: false }))
                      }
                      formControlSx={muiSelectFormControlSx}
                    />
                  </div>
                )}
              {!!promptData?.processType &&
                promptData?.model_type === "Image" && (
                  <div className="w-20">
                    <MuiSelect
                      label={"Variation"}
                      menuItems={
                        promptData?.llmVersionName === "dalle-3"
                          ? [1]
                          : imageVariations
                      }
                      value={promptData?.variationCount}
                      onChange={handleChangeVariation}
                      disabled={!promptData?.llmVersion}
                      formControlSx={muiSelectFormControlSx}
                    />
                  </div>
                )}
              {!!promptData?.processType &&
                promptData?.model_type === "Image" && (
                  <div className="w-32">
                    <MuiSelect
                      label={"Image size"}
                      menuItems={
                        promptData?.llmVersionName === "dalle-3"
                          ? dalle3ImageSizes
                          : availableImageSizes
                      }
                      menuItemClass={"w-30"}
                      value={promptData?.imageSize}
                      onChange={handleChangeSize}
                      disabled={!promptData?.llmVersion}
                      error={errors?.imageSize}
                      onFocus={() =>
                        setErrors((ini) => ({ ...ini, imageSize: false }))
                      }
                      formControlSx={muiSelectFormControlSx}
                    />
                  </div>
                )}

              {!promptData?.promptTabs?.includes("Report Dashboard") && (
                <Button
                  sx={{ background: colors.bgPrimary, height: "40px" }}
                  variant="outlined"
                  color={"secondary"}
                  startIcon={<HorizontalRuleIcon className="-rotate-[70deg]" />}
                  onClick={() =>
                    navigate("variables", {
                      state: {
                        ...location.state,
                        current_url: window.location.pathname,
                      },
                    })
                  }
                >
                  Variables
                </Button>
              )}

              {!isPreviewMode &&
                !promptData?.promptTabs?.includes("Report Dashboard") && (
                  <Button
                    onClick={() => setOpenDiscardChanges(true)}
                    variant="outlined"
                    color="secondary"
                    sx={{ background: colors.bgPrimary, height: "40px" }}
                    startIcon={<CancelPresentationIcon />}
                  >
                    Discard changes
                  </Button>
                )}
              <MuiModal
                open={openDiscardChanges}
                handleClose={() => setOpenDiscardChanges(false)}
                title={"Discard Changes"}
                message={
                  "Are you sure you want to discard the changes? All your current changes will be lost."
                }
                handleConfirm={() => {
                  setOpenDiscardChanges(false);
                  discardChangesHandler();
                }}
                showDeleteIcon={true}
              />

              {openDiscardPrompt && (
                <MuiModal
                  open={!!openDiscardPrompt}
                  handleClose={() => setOpenDiscardPrompt(false)}
                  title={"Are You Sure You Want to Leave?"}
                  message={
                    "Any unsaved changes will be lost. Please ensure you have saved your work."
                  }
                  handleConfirm={handleDiscardPrompt}
                  showDeleteIcon={true}
                />
              )}

              {!isPreviewMode && (
                <div className="w-36">
                  <MuiSelect
                    label={"Environment"}
                    showSelect={true}
                    value={promptData?.environment_id}
                    onChange={handleChangeEnvironment}
                    menuItems={allEnvironments?.items}
                    valueKey={"id"}
                    labelKey={"name"}
                    menuItemClass={"w-32"}
                    selectClassName={"bg-primary"}
                    formControlSx={muiSelectFormControlSx}
                  />
                </div>
              )}
              {!isPreviewMode &&
                !promptData?.promptTabs?.includes("Report Dashboard") && (
                  <Button
                    onClick={savePromptHandler}
                    variant="outlined"
                    color="secondary"
                    sx={{ background: colors.bgPrimary, height: "40px" }}
                    startIcon={<FileDraftIcon />}
                  >
                    Save changes
                  </Button>
                )}
              {!isPreviewMode &&
                promptData?.promptTabs?.includes("Report Dashboard") && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleOpenEditMode}
                    sx={{ background: colors.bgPrimary, height: "40px" }}
                    startIcon={<EditIcon />}
                    disabled={promptData?.comparisonProgress}
                  >
                    Edit
                  </Button>
                )}

              {promptData?.activeScreen === "Compare" ? (
                <Button
                  disabled={promptData?.comparisonProgress}
                  variant="contained"
                  onClick={createPdg}
                  endIcon={<RocketLaunchIcon />}
                  style={{ display: "none" }}
                >
                  Compare
                </Button>
              ) : (
                <Button
                  disabled={promptData?.id?.includes("newPromptId")}
                  variant="contained"
                  onClick={handleRunPromptHandler}
                  endIcon={<RocketLaunchIcon />}
                >
                  RUN
                </Button>
              )}
            </div>
          </div>
          <div className="w-full">
            <div className="flex items-center justify-center my-4">
              <div className="flex gap-4 p-1 rounded-full  bg-quaternary">
                {promptData?.promptTabs?.map((tab) => (
                  <div
                    className={`text-sm-semibold px-4 py-2 cursor-pointer  ${
                      promptData?.activeScreen == tab
                        ? "bg-secondary text-brandPrimary-light dark:text-textPrimary border-1 border-secondary rounded-full"
                        : "text-tertiary "
                    }`}
                    key={tab}
                    onClick={() => handleTabChange(tab)}
                  >
                    {tab}
                  </div>
                ))}
              </div>
            </div>

            {/* <div style={{ color: "black" }} ref={templateRef}>
              asdfsdfasdf
            </div> */}
            <div className="w-full h-full ">
              {Object.keys(promptData?.promptPanels || {})?.length > 0 ? (
                promptData?.activeScreen == "Compare" ? (
                  <PromptCompare
                    handleComparePrompt={() => {
                      handleComparePrompt();
                    }}
                    handleResetCompare={() => {
                      handleResetCompare();
                    }}
                  />
                ) : promptData?.activeScreen === "Prompt Data" &&
                  isPreviewMode ? (
                  <PromptData discardChangesHandler={discardChangesHandler} />
                ) : (
                  promptData?.promptPanels[promptData?.activeScreen]
                )
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PromptEditor;
