import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Modal,
  Slider,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllContexts } from "../../../../services/app/api";
import { MODEL_MAX_TOKEN_COUNT } from "../../../../services/constants";
import { handleChangEditPrompt } from "../../../../store/collectionsSlice";
import { useAuth } from "../../../Auth/AuthContext";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";
import MuiSelect from "../../../UI/MuiSelect";

const PromptSettings = () => {
  const dispatch = useDispatch();
  const { colors } = useThemeContext();
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice.editPromptsList
  );
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const { activeTeam } = useAuth();
  const [contextData, setContextData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [cacheCount, setCacheCount] = useState(1);
  const [switchChecked, setSwitchChecked] = useState(
    promptData?.cache_setting?.active || false
  );

  const handleProgressChange = (e, v) => {
    let data = {
      ...promptData,
      prompt_setting: {
        ...promptData?.prompt_setting,
        [e.target?.name]: v,
      },
    };
    dispatch(handleChangEditPrompt(data));
  };

  const handleVariationChange = (e, v) => {
    let data = {
      ...promptData,
      variationCount: v,
    };
    dispatch(handleChangEditPrompt(data));
  };

  useEffect(() => {
    let params = {
      page: 1,
      page_size: 500,
    };
    getAllContexts({ team_id: activeTeam, params }).then((res) =>
      setContextData(res.items)
    );
  }, []);

  const handleSwitchChange = (e) => {
    if (e.target.checked) {
      setOpenModal(true);
    } else {
      let data = {
        ...promptData,
        cache_setting: {
          ...promptData?.cache_setting,
          active: false,
          cache_count: 1,
        },
      };
      dispatch(handleChangEditPrompt(data));
      setSwitchChecked(false);
      setCacheCount(0);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSwitchChecked(false);
  };

  const handleSubmitCache = () => {
    let data = {
      ...promptData,
      cache_setting: {
        ...promptData?.cache_setting,
        active: true,
        cache_count: cacheCount,
      },
    };
    console.log("filled data: ", data);

    dispatch(handleChangEditPrompt(data));

    setOpenModal(false);
    setSwitchChecked(true);
  };

  useEffect(() => {
    if (promptData?.cache_setting?.active) {
      const cacheCountValue = promptData?.cache_setting?.cache_count ?? 1;
      setSwitchChecked(true);
      setCacheCount(cacheCountValue);
      if (promptData?.cache_setting?.cache_count === null) {
        const updatedPromptData = {
          ...promptData,
          cache_setting: {
            ...promptData.cache_setting,
            cache_count: cacheCountValue,
          },
        };

        if (
          updatedPromptData.cache_setting.cache_count !==
          promptData.cache_setting.cache_count
        ) {
          dispatch(handleChangEditPrompt(updatedPromptData));
        }
      }
    } else {
      setSwitchChecked(false);
      setCacheCount(0);

      const updatedPromptData = {
        ...promptData,
        cache_setting: {
          ...promptData.cache_setting,
          cache_count: 0,
        },
      };

      if (
        updatedPromptData.cache_setting.cache_count !==
        promptData.cache_setting.cache_count
      ) {
        dispatch(handleChangEditPrompt(updatedPromptData));
      }
    }
  }, [promptData]);

  return (
    <div className="w-full">
      <Stack
        gap={1}
        display={promptData?.model_type === "Text" ? "block" : "none"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={2}
          justifyContent={"space-between"}
        >
          <FormLabel
            htmlFor="temperature"
            sx={{ color: colors.textTertiary, fontSize: 14 }}
          >
            Temperature:
          </FormLabel>
          <div className="w-[70%]">
            <Slider
              defaultValue={0.5}
              step={0.1}
              max={1}
              min={0}
              aria-label="Temperature"
              name="temperature"
              valueLabelDisplay="auto"
              onChange={handleProgressChange}
              value={promptData?.prompt_setting?.temperature}
            />
          </div>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={2}
          justifyContent={"space-between"}
        >
          <FormLabel
            htmlFor="variation_count"
            sx={{ color: colors.textTertiary, fontSize: 14 }}
          >
            Variation:
          </FormLabel>
          <div className="w-[70%]">
            <Slider
              defaultValue={1}
              step={1}
              max={promptData?.llmModelName !== "anthropic" ? 4 : 1}
              min={1}
              aria-label="response"
              name="variation_count"
              valueLabelDisplay="auto"
              value={promptData?.variationCount}
              onChange={handleVariationChange}
            />
          </div>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={2}
          justifyContent={"space-between"}
        >
          <FormLabel
            htmlFor="token"
            sx={{ color: colors.textTertiary, fontSize: 14 }}
          >
            Maximum tokens to use:
          </FormLabel>
          <div className="w-[70%]">
            <Slider
              defaultValue={3000}
              step={1}
              max={MODEL_MAX_TOKEN_COUNT[promptData?.llmModelName] || 10000}
              min={1}
              aria-label="token"
              name="max_token"
              valueLabelDisplay="auto"
              value={promptData?.prompt_setting?.max_token}
              onChange={handleProgressChange}
            />
          </div>
        </Box>
      </Stack>

      <div className="flex justify-between items-center gap-2">
        <FormControlLabel
          control={
            <Switch checked={switchChecked} onChange={handleSwitchChange} />
          }
          label={<span className="text-sm">Use cache</span>}
          sx={{ fontSize: 14, color: colors.textTertiary }}
        />
        {switchChecked && (
          <div className="flex items-center gap-2">
            <TextField
              label="Cache Count"
              type="text"
              value={cacheCount || ""}
              variant="outlined"
              size="small"
              sx={{ width: 100 }}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "" || /^[0-9]*$/.test(value)) {
                  const numValue = value === "" ? "" : Number(value);
                  if (numValue === "" || (numValue >= 1 && numValue <= 20)) {
                    setCacheCount(numValue);
                    let data = {
                      ...promptData,
                      cache_setting: {
                        ...promptData?.cache_setting,
                        cache_count: numValue,
                      },
                    };
                    dispatch(handleChangEditPrompt(data));
                  }
                }
              }}
              inputProps={{
                pattern: "[0-9]*",
              }}
            />
          </div>
        )}

        <div className="flex justify-end items-center gap-2">
          <FormLabel sx={{ color: colors.textTertiary, fontSize: 14 }}>
            Refresh cache every:
          </FormLabel>
          <div className="w-32">
            <MuiSelect
              value={promptData?.cache_setting?.interval}
              selectClassName="bg-primary"
              menuItems={[
                { value: "day", label: "Day" },
                { value: "week", label: "Week" },
                { value: "month", label: "Month" },
              ]}
              onChange={(e) => {
                let data = {
                  ...promptData,
                  cache_setting: {
                    ...promptData?.cache_setting,
                    interval: e.target.value,
                  },
                };
                dispatch(handleChangEditPrompt(data));
              }}
            />
          </div>
        </div>
      </div>

      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            <h1 className="text-primary">Select Cache Count</h1>
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            How many cache results would you like to store?
          </Typography>
          <Slider
            value={cacheCount}
            onChange={(e, v) => setCacheCount(v)}
            step={1}
            min={2}
            max={20}
            valueLabelDisplay="auto"
          />
          <div className="flex justify-end mt-4">
            <Button onClick={handleSubmitCache} variant="contained">
              Submit
            </Button>
            <Button
              onClick={handleModalClose}
              variant="outlined"
              sx={{ ml: 1 }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PromptSettings;
